import './App.css';
import React from 'react';

const App: React.FC<{}> = () => {
  return (
    <div className="App">
      <div className="App-header">
        <img src={'/logo.png'} className="App-logo" alt="logo" />
        <h1 className="text-5xl font-bold underline">
          Est. 2011 - 2021
        </h1>
        <h2>
          <strong>Thank you for 10 years!</strong>
        </h2>
        <p>
          COVID-19's effects on the market took a hard toll on ourselves and our customers in 2021. The rising cost of living continues to affect us.<br /><br />
          For our mental health and financial wellbeing, it was, unfortunately, a necessity to shut down CubedHost.<br />
          If we had continued, we would have been on a course for a much worse ending. One that wouldn't have been amicable.<br />
          We hope that everyone is doing well amidst these trying times.
        </p>
        <p>
          20% of small businesses fail within the first year.<br />
          50% fail within five years.<br />
          <strong>We made it to 10 years. Thank you.</strong>
        </p>
        <p>
          <i>Our story continues at <a href='https://prisma.dev/'>prisma.dev</a>.</i>
        </p>
        <p>
          <a href='https://discord.gg/gvC8HgpS5G'>Discord</a> |{' '}
          <a href='https://groups.google.com/a/prisma.dev/g/ml'>Mailing List</a> |{' '}
          <a href='https://www.bonfire.com/store/cubedhost-nostalgia/'>Nostalgia Merch</a> |{' '}
          <a href='https://github.com/PrismaGSM'>GitHub</a>
        </p>
      </div>
    </div>
  );
}

export default App;